//@mui material components
import Icon from "@mui/material/Icon";

//@mui icons
import GitHubIcon from "@mui/icons-material/GitHub";
import BadgeIcon from '@mui/icons-material/Badge';
//Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Author from "layouts/pages/landing-pages/author";
import SignIn from "layouts/pages/authentication/sign-in";

//Sections
import PageHeaders from "layouts/sections/page-sections/page-headers";
import Features from "layouts/sections/page-sections/featuers";
import Navbars from "layouts/sections/navigation/navbars";
import NavTabs from "layouts/sections/navigation/nav-tabs";
import Pagination from "layouts/sections/navigation/pagination";
import Inputs from "layouts/sections/input-areas/inputs";
import Forms from "layouts/sections/input-areas/forms";
import Alerts from "layouts/sections/attention-catchers/alerts";
import Modals from "layouts/sections/attention-catchers/modals";
import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
import Avatars from "layouts/sections/elements/avatars";
import Badges from "layouts/sections/elements/badges";
import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
import Buttons from "layouts/sections/elements/buttons";
import Dropdowns from "layouts/sections/elements/dropdowns";
import ProgressBars from "layouts/sections/elements/progress-bars";
import Toggles from "layouts/sections/elements/toggles";
import Typography from "layouts/sections/elements/typography";

const routes = [
  {
    name: "Home",
    icon: <Icon>home</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    // collapse: [
    //   {
    //     name: "landing pages",
    //     collapse: [
    //       {
    //         name: "about us",
    //         route: "/pages/landing-pages/about-us",
    //         component: <AboutUs />,
    //       },
    //       {
    //         name: "contact us",
    //         route: "/pages/landing-pages/contact-us",
    //         component: <ContactUs />,
    //       },
    //       {
    //         name: "author",
    //         route: "/pages/landing-pages/author",
    //         component: <Author />,
    //       },
    //     ],
    //   }
    // ],
  },
  {
    name: "Services",
    icon: <Icon>view_day</Icon>,
    collapse: [
      {
        name: "Mobile App Development",
        description: "See all sections",
        dropdown: true,
        collapse: [
          {
            name: "iOS App Development",
            route: "/sections/page-sections/page-headers",
            component: <PageHeaders />,
          },
          {
            name: "Android App Development",
            route: "/sections/page-sections/features",
            component: <Features />,
          },
          {
            name: "React Native App Development",
            route: "/sections/page-sections/features",
            component: <Features />,
          },
          {
            name: "Flutter App Development",
            route: "/sections/page-sections/features",
            component: <Features />,
          },
        ],
      },
      {
        name: "Web App Development",
        description: "See all navigations",
        dropdown: true,
        collapse: [
          {
            name: "React Development",
            route: "/sections/navigation/navbars",
            component: <Navbars />,
          },
          {
            name: "NodeJS Development",
            route: "/sections/navigation/nav-tabs",
            component: <NavTabs />,
          },
          {
            name: "Angular Development",
            route: "/sections/navigation/pagination",
            component: <Pagination />,
          },
          {
            name: "Python Development",
            route: "/sections/navigation/pagination",
            component: <Pagination />,
          }
        ],
      },
      {
        name: "Desktop App Development",
        description: "See all input areas",
        dropdown: true,
        collapse: [
          {
            name: "Mac OS App Development",
            route: "/sections/input-areas/inputs",
            component: <Inputs />,
          },
          {
            name: "Windows App Development",
            route: "/sections/input-areas/forms",
            component: <Forms />,
          },
        ],
      },
      {
        name: "Digital Marketing",
        description: "See all examples",
        dropdown: true,
        collapse: [
          {
            name: "SEO Services",
            route: "/sections/attention-catchers/alerts",
            component: <Alerts />,
          },
          {
            name: "App Store Optimizations services",
            route: "/sections/attention-catchers/modals",
            component: <Modals />,
          },
          {
            name: "Content writing services",
            route: "/sections/attention-catchers/tooltips-popovers",
            component: <TooltipsPopovers />,
          },
        ],
      },
      {
        name: "E-Commerse Development",
        description: "See all 32 examples",
        dropdown: true,
        collapse: [
          {
            name: "Magento Developent",
            route: "/sections/elements/avatars",
            component: <Avatars />,
          },
          {
            name: "Salesforce Development",
            route: "/sections/elements/badges",
            component: <Badges />,
          },
          {
            name: "Shopify Development",
            route: "/sections/elements/breadcrumbs",
            component: <BreadcrumbsEl />,
          }
          // {
          //   name: "buttons",
          //   route: "/sections/elements/buttons",
          //   component: <Buttons />,
          // },
          // {
          //   name: "dropdowns",
          //   route: "/sections/elements/dropdowns",
          //   component: <Dropdowns />,
          // },
          // {
          //   name: "progress bars",
          //   route: "/sections/elements/progress-bars",
          //   component: <ProgressBars />,
          // },
          // {
          //   name: "toggles",
          //   route: "/sections/elements/toggles",
          //   component: <Toggles />,
          // },
          // {
          //   name: "typography",
          //   route: "/sections/elements/typography",
          //   component: <Typography />,
          // },
        ],
      },
    ],
  },
  {
    name: "Company",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "About US",
        description: "All about overview, quick start, license and contents",
        href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-kit/",
      },
      {
        name: "Contact us",
        description: "See our colors, icons and typography",
        href: "https://www.creative-tim.com/learning-lab/react/colors/material-kit/",
      },
      {
        name: "Why Zenith AppsTech",
        description: "Explore our collection of fully designed components",
        href: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
      },
      {
        name: "Mission & Vision",
        description: "Check how you can integrate our plugins",
        href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-kit/",
      },
    ],
  },
  {
    name: "Careers",
    icon: <BadgeIcon />,
    href: "",
  },
];

export default routes;
